@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

html {
  background: @body-background url("../images/banner.png") 0px -150px no-repeat fixed;
}
body,
.not-for-ansatte #head-wrapper {
  background: transparent;
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-primary !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }

  #head-wrapper {
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png")
        no-repeat scroll 90% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 150px;
      .head-menu {
        right: 110px;
        //.language { display: none;}
        a {
          color: @color-dark;
          background-color: @color-light;
          margin-top: -5px;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header img {
        position: absolute;
        top: 8px;
        //margin-left: -85px;
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  width: 1210px;
  margin: 0 auto;
  ul {
    line-height: 2rem;
    li {
      a,
      &.vrtx-active-item a {
        color: @color-light;
        &:hover,
        &:focus {
          background: @color-neutral--light none repeat scroll 0 0;
          color: @color-dark;
          margin-top: 0;
        }
      }
      &.vrtx-active-item a {
        background: @color-light none repeat scroll 0 0;
        color: @color-neutral--dark;
      }
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-neutral--light;
      border-top: none !important;
      background: rgba(0, 0, 0, 0)
        url("/vrtx/decorating/resources/dist/images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-neutral--air !important;
      color: @color-dark;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}
.not-for-ansatte #main,
.vrtx-frontpage-full-width.total-main #main,
.vrtx-frontpage-full-width.total-main #main #total-main #vrtx-content,
.vrtx-frontpage-full-width.total-main #main #total-main #vrtx-main-content {
  margin-left: auto;
  margin-right: auto;
  width: 1050px;
  padding: 20px 40px;
  background: @color-light;
  margin-top: 0px;
}

table th {
  font-weight: bold;
}

#main .vrtx-has-search-scope .vrtx-search-scope {
  display: none;
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid #eaeaea;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-green.svg")
    no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-green.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-green.svg");
  }
}

/* Kart */
#norden-kart {
  background: url("../images/norden-hover.png") no-repeat scroll -13px 5px rgba(0, 0, 0, 0);
  height: 444px;
  width: 529px;
  li {
    position: relative;
    background: none !important;
    span {
      opacity: 1;
      position: absolute;
    }
    a {
      color: transparent !important;
      position: absolute;
      left: 0;
      top: 0 !important;
      height: 25px;
      width: 40px;
      margin-left: 13px;
      z-index: 1;
    }
    &#norge {
      left: 675px;
      top: 75px;
      a {
        margin-left: 191px;
        margin-top: 15px;
        padding: 5px;
      }
      > span {
        height: 282px !important;
        margin-left: -439px;
        margin-top: -57px;
        width: 126px !important;
      }
      &:hover > span {
        background: url("../images/norden-hover.png") no-repeat scroll -390px -593px
          #e5e5e5;
      }
    }
    &#danmark {
      left: 675px;
      top: 129px;
      a {
        margin-left: 173px;
        margin-top: -213px;
        padding: 5px;
      }
      > span {
        height: 53px !important;
        margin-left: -421px;
        margin-top: 171px;
        width: 49px !important;
      }
      &:hover > span {
        background: url("../images/norden-hover.png") no-repeat scroll -288px -580px
          #e5e5e5;
      }
    }
    &#finland {
      left: 675px;
      top: 193px;
      a {
        margin-left: 125px;
        margin-top: 34px;
        padding: 5px;
      }
      > span {
        height: 150px !important;
        margin-left: -373px;
        margin-top: -76px;
        width: 96px !important;
      }
      &:hover > span {
        background: url("../images/norden-hover.png") no-repeat scroll -166px -717px
          #e5e5e5;
      }
    }

    &#feroyene {
      left: 675px;
      top: 245px;
      a {
        margin-left: 260px;
        margin-top: -17px;
        padding: 5px;
      }
      > span {
        height: 16px !important;
        margin-left: -508px;
        margin-top: -25px;
        width: 17px !important;
      }
      &:hover > span {
        background: url("../images/norden-hover.png") no-repeat scroll -201px -670px
          #e5e5e5;
      }
    }
    &#gronland {
      left: 675px;
      top: 304px;
      a {
        margin-left: 451px;
        margin-top: 268px;
        padding: 5px;
      }
      > span {
        height: 150px !important;
        margin-left: -698px;
        margin-top: -310px;
        width: 230px !important;
      }
      &:hover > span {
        background: url("../images/norden-hover.png") no-repeat scroll -11px -444px
          #e5e5e5;
      }
    }
    &#island {
      left: 675px;
      top: 358px;
      a {
        margin-left: 334px;
        margin-top: 166px;
        padding: 5px;
      }
      > span {
        height: 49px !important;
        margin-left: -581px;
        margin-top: -208px;
        width: 57px !important;
      }
      &:hover > span {
        background: url("../images/norden-hover.png") no-repeat scroll -128px -600px
          #e5e5e5;
      }
    }
    &#sverige {
      left: 675px;
      top: 420px;
      a {
        margin-left: 150px;
        margin-top: 222px;
        padding: 5px;
      }
      > span {
        height: 178px !important;
        margin-left: -398px;
        margin-top: -265px;
        width: 67px !important;
      }
      &:hover > span {
        background: url("../images/norden-hover.png") no-repeat scroll -311px -695px
          #e5e5e5;
      }
    }
  }
}
/* Kart ^*/

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @footer-color;
    *,
    a {
      color: @color-neutral--dark;
    }
    #footers {
      .social-components {
        display: flex;
        flex-wrap: wrap;
        height: 150px;
        a {
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          width: 45px;
          height: 45px;
          &.facebook,
          &.twitter-x,
          &.mailto,
          &.instagram,
          &.youtube {
            color: transparent;
            margin-left: 10px;
            padding: 0 0 34px 30px;
            &:hover,
            &:focus {
              color: transparent;
              background-color: transparent;
              opacity: 0.6;
            }
          }

          &.facebook {
            background-image: url("../uio1/images/social-list/black-svg/facebook.svg");
          }
          &.twitter-x {
            background-image: url("../uio1/images/social-list/black-svg/twitter-x.svg");
          }
          &.instagram {
            background-image: url("../uio1/images/social-list/black-svg/instagram.svg");
          }
          &.mailto {
            background-image: url("../uio1/images/social-list/black-svg/mail.svg");
          }
          &.youtube {
            background-image: url("../uio1/images/social-list/black-svg/youtube.svg");
          }
        }
      }
      .vrtx-dropdown-component-toggled .vrtx-login-manage-link{
        background: rgba(0, 0, 0, 0)
          url("../uio1/images/dropdown-black.png")
          no-repeat scroll right center / 22px auto;
        &:focus,
        &:hover {
          background-image: url("../uio1/images/dropdown-black-hover.png");
        }
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: @body-background url("../images/banner.png") 0px -150px no-repeat
        fixed;
      #head {
        height: 142px;
        #header {
          img {
            padding: 0 10px 10px;
            top: 0;
            margin-left: 0px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }

  .not-for-ansatte #main,
  .vrtx-frontpage-full-width.total-main #main,
  .vrtx-frontpage-full-width.total-main #main #total-main #vrtx-content,
  .vrtx-frontpage-full-width.total-main #main #total-main #vrtx-main-content {
    width: 100%;
    padding: 0;
  }
  #norden-kart {
    background: none;
    height: auto;
    li {
      &#norge,
      &#danmark,
      &#finland,
      &#feroyene,
      &#gronland,
      &#island,
      &#sverige {
        left: 0;
        top: auto;
        span {
          position: relative;
          margin-left: 0px;
          a {
            color: @color-link !important;
            margin-left: 0 !important;
            position: relative;
          }
        }
      }
      &:hover > span {
        margin-left: 0;
        background: none !important;
      }
    }
  }

  .menu-search {
    input[type="text"] {
      border-right-width: 5em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary : #594366;
@color-primary--dark : darken(spin(@color-primary, -5), 10%);
@color-secondary : #1DAFEC;
@color-secondary--dark : darken(spin(@color-secondary, -5), 10%);
@color-link : #2771bb;

// Neutral colors
@color-neutral--dark : #2b2b2b;
@color-neutral : #949494;
@color-neutral--light : #EAEDEE;
@color-neutral--air : #e5e5e5;
@color-lines : #e4e4e4;
@color-box-shadow : #777777;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #000000;
@footer-color : #9ACBAD;
@body-background : #060814;
